<template>
  <b-modal
    :id="id"
    size="md"
    title="Gia hạn giấy phép"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleExtendLicense"
    @hidden="resetState"
  >
    <div class="modal-accept">
      <b-form class="content-form">
        <b-form-group>
          <label>Ngày hiệu lực</label>
          <date-time-picker
            v-model="dataLicense.dateLicense"
            :disabledInput="true"
          />
        </b-form-group>

        <b-form-group>
          <label>Ngày hết hiệu lực</label>
          <date-time-picker
            v-model="dataLicense.dateExpired"
            :disabledInput="true"
          />
        </b-form-group>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // customCode: {
      //   required: 'Quyết định số là bắt buộc',
      // },
      dataLicense: {
        dateLicense: '',
        dateExpired: '',
      },
    }
  },
  methods: {
    handleExtendLicense() {
      const dateEnd = new Date(this.dataLicense.dateExpired).getTime()
      const datestart = new Date(this.dataLicense.dateLicense).getTime()

      if (datestart > dateEnd) {
        this.$root.$bvToast.toast('Ngày hết hiệu lực không nhỏ hơn ngày hiệu lực', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        this.$emit('handleExtendLicense', this.dataLicense)
      }
    },
    resetState() {
      this.dataLicense = {
        dateLicense: '',
        dateExpired: '',
      }
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
